/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/header';

export default () => (
  <>
    <Header className="header__links" />
    <h1
      style={{
        color: '#c5c6c7',
        textAlign: 'center',
        paddingTop: '10rem',
        fontSize: '3rem',
      }}
    >
      {' '}
      Page not found{' '}
    </h1>
    <p
      style={{
        color: '#c5c6c7',
        textAlign: 'center',
        paddingTop: '5rem',
        fontSize: '3rem',
      }}
    >
      This page can't be found please return to home
    </p>
    <div style={{ textAlign: 'center' }}>
      <Link
        to="/"
        style={{
          color: '#c5c6c7',
          paddingTop: '10rem',
          fontSize: '3rem',
          textDecoration: 'none',
          display: 'inline-block',
        }}
      >
        &larr; Back to home
      </Link>
    </div>
  </>
);
